import React from "react"
import Link from "gatsby-link"
import Layout from "../components/Layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Page not found.</h1>
    <p>This page doesn't exist.</p>
    <p>
      <Link to="/">Return to homepage</Link>
    </p>
  </Layout>
)

export default NotFoundPage
